<template>
    <div id="allmap"></div>
</template>
<script>
import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import { BMPGL } from "@/utils/bmpgl.js"
export default {
    setup() { 
        onMounted(()=>{
          BMPGL("aXRqcbthr5kBMdVTCxrnb6mIUz4FPbSE").then((BMapGL) => {
            var map = new BMapGL.Map("allmap");
            map.centerAndZoom(new BMapGL.Point(116.180631,40.066051), 19);
            // map.enableScrollWheelZoom(true);
            map.setHeading(64.5);
            map.setTilt(73);
            var point = new BMapGL.Point(116.180631,40.066051);
            var marker = new BMapGL.Marker(point);
            map.addOverlay(marker);
            var opts = {
                width: 300,
                height: 80,
                title: '北京星戟技术有限公司'
            };
            var infoWindow = new BMapGL.InfoWindow('地址：北京市海淀区紫雀路33号院3号楼联动骏一国际创科中心四层3438', opts);
            map.openInfoWindow(infoWindow, point);
            marker.addEventListener("click", function(){          
		          map.openInfoWindow(infoWindow, point); //开启信息窗口
	          }); 
            var scaleCtrl = new BMapGL.ScaleControl();
            map.addControl(scaleCtrl);
            var zoomCtrl = new BMapGL.ZoomControl(); 
            map.addControl(zoomCtrl);
            var navi3DCtrl = new BMapGL.NavigationControl3D();  // 添加3D控件
    map.addControl(navi3DCtrl);
//             // 自定义展示内容
// function createDOM() {
//     var div = document.createElement('div');
//     div.style.zIndex = BMapGL.Overlay.getZIndex(this.point.lat);
//     div.style.backgroundColor = '#fff';
//     div.style.color = '#333';
//     div.style.height = '80px';
//     div.style.width = '270px';
//     div.style.padding = '20px 10px';
//     div.style.lineHeight = '50px';
//     // div.style.whiteSpace = 'nowrap';
//     // div.style.MozUserSelect = 'none';
//     div.style.fontSize = '14px';
//     div.style.display = 'flex';
//     div.style.flexDirection = 'column';
//     var title = document.createElement('div');
//     title.style.display = 'block';
//     title.style.lineHeight = '16px';
//     title.style.fontSize = '1rem';
//     div.appendChild(title);
//     title.appendChild(document.createTextNode(this.properties.title));
//     var span = document.createElement('span');
//     span.style.wordWrap = 'break-word';
//     span.style.lineHeight = '16px';
//     span.style.wordWrap = 'break-word';
//     span.style.whiteSpace = 'normal';
//     span.style.paddingTop = '10px';
//     span.style.color = '#666';
//     div.appendChild(span);
//     span.appendChild(document.createTextNode(this.properties.text));

//     // let img = document.createElement('img');
//     // img.style.width = '120px';
//     // img.src = this.properties.imgSrc;
//     // div.appendChild(img);

//     // var arrow = document.createElement('div');
//     // arrow.style.position = 'absolute';
//     // arrow.style.top = '144px';
//     // arrow.style.left = '120px';
//     // arrow.style.width = '0';
//     // arrow.style.height = '0';
//     // arrow.style.borderColor = 'white transparent transparent transparent';
//     // arrow.style.borderStyle = 'solid';
//     // arrow.style.borderWidth = '10px';
//     // arrow.style.overflow = 'hidden';
//     // div.appendChild(arrow);

//     // div.onmouseover = function () {
//     //     this.style.backgroundColor = 'skyblue';
//     //     this.style.color = '#fff';
//     //     span.style.color = '#fff';
//     //     // arrow.style.top = '164px';
//     //     arrow.style.borderColor = 'skyblue transparent transparent transparent';
//     // };

//     // div.onmouseout = function () {
//     //     this.style.backgroundColor = '#fff';
//     //     this.style.color = '#333';
//     //     span.style.color = '#333';
//     //     arrow.style.borderColor = 'white transparent transparent transparent';
//     // };
//     return div;
// }
// // 创建自定义覆盖物
// var customOverlay = new BMapGL.CustomOverlay(createDOM, {
//     point: new BMapGL.Point(116.180631,40.066051),
//     // opacity: 0.8,
//     offsetY: -85,
//     properties: {
//         title: '北京星戟技术有限公司',
//         text: '地址：北京市海淀区紫雀路33号院3号楼联动骏一国际创科中心四层3438',
//         // imgSrc: 'img/logo.89f26eeb.png'
//     }
// });
// map.addOverlay(customOverlay);
        })
      })
    }
}
</script>
<style scoped>
#allmap{
  width: 100%;
  height: 600px;
}
</style>